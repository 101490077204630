import { useEffect, useState } from 'react';
import { Panel, FormWrapper } from 'component/common';
import { Main as Provider } from 'component/context';
import { Form, Row } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import { useNavigate } from 'react-router-dom';
import { Formik, useFormikContext } from 'formik';
import * as yup from 'yup';

import S from 'theme/structure';

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const FormProcessor = ({ cities, setToCities }) => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        const newToCities = cities.to[values.from] ?? [];

        setToCities(newToCities);

        if (!newToCities.includes(values.to)) setFieldValue('to', '');
    }, [values.from, values.to, setFieldValue, cities.to, setToCities]);
};

const Component = () => {
    const { cities, airports, user, userReadingData } = Provider.useContext();

    const navigate = useNavigate();

    const [toCities, setToCities] = useState([]);
    const [form, setForm] = useState(false);

    const setCitiesOptions = (cities) => {
        if (!cities || !cities.length)
            return <option disabled>There is no cities</option>;

        return cities.map((c) => (
            <option key={c} value={c}>
                {c}
            </option>
        ));
    };

    useEffect(() => {
        if (form) {
            if (!user || (user && !userReadingData)) {
                const data = {
                    from: airports[form.from]?.code ?? undefined,
                    to: airports[form.to]?.code ?? undefined,
                    date: new Intl.DateTimeFormat('en-CA', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                    }).format(form.date),
                };

                navigate(`/flights/${data.from}/${data.to}/${data.date}`);
            }
        }
    }, [form, user, userReadingData, navigate, airports]);

    return (
        <S.Container.Page>
            <Panel header="Book a flight">
                <Formik
                    validationSchema={yup.object({
                        from: yup.string().required('Please select a city'),
                        to: yup.string().required('Please select a city'),
                        date: yup.date().required('Please select a date'),
                    })}
                    onSubmit={setForm}
                    initialValues={{
                        from: '',
                        to: '',
                        date: new Date(),
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        setFieldTouched,
                        values,
                        touched,
                        errors,
                    }) => (
                        <S.Form.Container
                            id="form-flight-search"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <FormProcessor
                                cities={cities}
                                setToCities={setToCities}
                            />
                            <FormWrapper.Row label="From" error={errors.from}>
                                <Form.Select
                                    id="from"
                                    value={values.from}
                                    onChange={handleChange}
                                    isInvalid={touched.from && !!errors.from}
                                >
                                    <option key="blankChoice" hidden value>
                                        Select origin
                                    </option>
                                    {setCitiesOptions(cities.from)}
                                </Form.Select>
                            </FormWrapper.Row>
                            <FormWrapper.Row label="To" error={errors.to}>
                                <Form.Select
                                    id="to"
                                    value={values.to}
                                    onChange={handleChange}
                                    isInvalid={touched.to && !!errors.to}
                                >
                                    <option key="blankChoice" hidden value>
                                        Select destination
                                    </option>
                                    {setCitiesOptions(toCities)}
                                </Form.Select>
                            </FormWrapper.Row>
                            <FormWrapper.Row
                                label="Departure date"
                                error={errors.date}
                            >
                                <Row
                                    id="date"
                                    className={
                                        touched.date && !!errors.date
                                            ? 'is-invalid'
                                            : ''
                                    }
                                >
                                    <DatePicker
                                        clearIcon={null}
                                        format={'dd/MMM/y'}
                                        value={values.date}
                                        onChange={(date) => {
                                            setFieldValue('date', date);
                                            if (!date) setFieldTouched('date');
                                        }}
                                        minDate={new Date()}
                                    />
                                </Row>
                            </FormWrapper.Row>
                            <S.Form.Buttons.Container>
                                <S.Form.Buttons.Button
                                    type="submit"
                                    size="lg"
                                    variant="primary"
                                    disabled={form && user && userReadingData}
                                >
                                    {form && user && userReadingData
                                        ? 'Searching ...'
                                        : 'Search flights'}
                                </S.Form.Buttons.Button>
                            </S.Form.Buttons.Container>
                        </S.Form.Container>
                    )}
                </Formik>
            </Panel>
        </S.Container.Page>
    );
};

export default Component;
