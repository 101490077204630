import React, { createContext, useContext as useReactContext } from 'react';

import { SAML } from 'component/api';

export const Context = createContext();

export const Provider = ({ children }) => {
    const login = () => SAML.login();

    return (
        <Context.Provider
            value={{
                samlLogin: login,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('useContext should be used inside Provider');
    }
    return context;
};
