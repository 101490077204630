export const serialize = (data) => {
    const jsonString = JSON.stringify(data);
    const utf8Encoder = new TextEncoder();
    const encodedData = utf8Encoder.encode(jsonString);
    return btoa(String.fromCharCode.apply(null, encodedData));
};

export const deserialize = (data) => {
    const base64Decoded = atob(data);
    const bytes = new Uint8Array(
        base64Decoded.split('').map((char) => char.charCodeAt(0))
    );
    const utf8Decoder = new TextDecoder();
    const decodedString = utf8Decoder.decode(bytes);
    return JSON.parse(decodedString);
};
