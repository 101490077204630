export const formatDate = ({ date, type = 'long' }) => {
    const monthNames = [
        ['Jan', 'January'],
        ['Feb', 'February'],
        ['Mar', 'March'],
        ['Apr', 'April'],
        ['May', 'May'],
        ['Jun', 'June'],
        ['Jul', 'July'],
        ['Aug', 'August'],
        ['Sep', 'September'],
        ['Oct', 'October'],
        ['Nov', 'November'],
        ['Dec', 'December'],
    ];
    const dayNames = [
        ['Sun', 'Sunday'],
        ['Mon', 'Monday'],
        ['Tue', 'Tuesday'],
        ['Wed', 'Wednesday'],
        ['Thu', 'Thursday'],
        ['Fri', 'Friday'],
        ['Sat', 'Saturday'],
    ];
    const t = type === 'long' ? 1 : 0;
    const result = [];
    result.push(dayNames[date.getDay()][t]);

    result.push(`${date.getDate()} ${monthNames[date.getMonth()][t]}`);

    if (date.getFullYear() !== new Date().getFullYear()) {
        result.push(date.getFullYear());
    }
    return result.join(', ');
};
