import { Panel } from 'component/common';
import { Main as Provider } from 'component/context';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Flight } from 'component/common';

import S from 'theme/structure';

const Component = () => {
    const { lines } = Provider.useContext();
    const navigate = useNavigate();

    const { fromCode, toCode, date } = useParams();

    const values = lines[fromCode + toCode] ?? [];

    const flightSelection = (id) => () => navigate(`/book/${id}/${date}`);

    return (
        <S.Container.Page>
            <Panel header="Select your flight">
                <div id="flights-list">
                    {values.map((flight) => (
                        <Flight
                            key={flight.id}
                            flight={flight}
                            selected={flightSelection(flight.id)}
                        />
                    ))}
                </div>
            </Panel>
        </S.Container.Page>
    );
};

export default Component;
