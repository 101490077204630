import { Main as Layout } from 'component/layout';
import { Flight } from 'component/page';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

const RouterWrapper = ({ children }) => (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Layout />}>
                {children}
            </Route>
        </Routes>
    </BrowserRouter>
);

const Component = () => {
    return (
        <RouterWrapper>
            <Route index element={<Flight.Search />} />
            <Route
                path="flights/:fromCode/:toCode/:date"
                element={<Flight.Selection />}
            />
            <Route path="book/:flightId/:date" element={<Flight.Book />} />
            <Route path="confirmation" element={<Flight.Confirmation />} />
        </RouterWrapper>
    );
};

export default Component;
