import React, {
    createContext,
    useContext as useReactContext,
    useState,
    useEffect,
    useCallback,
} from 'react';

import { useWebPubSub } from 'component/hook';
import { User } from 'component/api';
import * as Utils from './Utils';

export const Context = createContext();

export const Provider = ({ children }) => {
    const [user, setUser] = useState(Utils.storage.local.json.read('user'));

    const [error, setError] = useState(null);
    const [apiResult, setApiResult] = useState(null);
    const [readingData, setReadingData] = useState(false);

    const loadings = {
        signOut: useState(false),
    };

    const ReadUserData = useWebPubSub({
        hookId: 'ReadData',
        operation: () => User.readData(),
        triggerText: 'Read user data',
        actions: {
            'airscout-backend': {
                read_data: {
                    final: true,
                    trigger: ({ preferences, profile }) => {
                        setReadingData(false);
                        if (user && (preferences || profile)) {
                            setUser({
                                ...user,
                                ...(preferences ? { preferences } : {}),
                                ...(profile ? { profile } : {}),
                            });
                        }
                    },
                },
            },
        },
    });

    useEffect(() => {
        const parameters = new URLSearchParams(window.location.search);
        const r = parameters.get('session_id');

        if (r) {
            if (user) {
                localStorage.setItem('readUserPreferences', 'true');
                window.location.href = window.location.origin;
            } else {
                localStorage.removeItem('readUserPreferences');
                User.recoverSession(r).then(({ value, error }) => {
                    if (error) {
                        window.alert('Error recovering session');
                        window.location.href = window.location.origin;
                    } else {
                        setUser(value);
                    }
                });
            }
        } else {
            if (user && localStorage.getItem('readUserPreferences')) {
                localStorage.removeItem('readUserPreferences');
                ReadUserData.performOperation();
                setReadingData(true);
            }
        }
    }, [user, ReadUserData]);

    useEffect(() => {
        if (
            JSON.stringify(user) !==
            JSON.stringify(Utils.storage.local.json.read('user'))
        ) {
            setUser(
                Utils.storage.local.json.store('user', user, [
                    'username',
                    'profile',
                    'preferences',
                ])
            );
        }
    }, [user]);

    const op = Utils.operationCaller(setError, setApiResult);

    const logoutUser = useCallback(() => {
        setUser(null);
        if (window.location.href !== window.location.origin) {
            window.location.href = window.location.origin;
        }
    }, [setUser]);

    const operations = {
        signOut: () =>
            op({
                state: loadings['signOut'],
                operation: () => User.signOut(),
                callbacks: {
                    200: logoutUser,
                    403: logoutUser,
                },
            }),
    };

    return (
        <Context.Provider
            value={{
                user,
                setUser,
                userError: error,
                userApiResult: apiResult,
                ...Utils.operationsToJSON(operations),
                ...Utils.loadingsToJSON(loadings),
                userReadingData: readingData,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('useContext should be used inside Provider');
    }
    return context;
};
