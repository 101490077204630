import plane from 'images/plane.svg';

import S from 'theme/structure';

const { Row } = S.Flight;

const DescriptionAirport = ({ info, type }) => (
    <Row.Description.Airport.Container subclasses={[type]}>
        <Row.Description.Airport.Code subclasses={[type]}>
            {info.airport.code}
        </Row.Description.Airport.Code>
        <Row.Description.Airport.Time subclasses={[type]}>
            {info.time}
        </Row.Description.Airport.Time>
    </Row.Description.Airport.Container>
);

const Component = ({ flight, selected }) => (
    <Row.Container>
        <Row.Col subclasses={['left']}>
            <Row.CompanyLogo
                src={flight.company.image}
                alt={`${flight.company.name} logo`}
            />
        </Row.Col>
        <Row.Col subclasses={['center']}>
            <Row.Description.Container>
                <DescriptionAirport info={flight.departure} type="origin" />
                <Row.Description.Icon.Container>
                    <Row.Description.Icon.Image src={plane} alt="plane" />
                </Row.Description.Icon.Container>
                <DescriptionAirport info={flight.arrival} type="destination" />
            </Row.Description.Container>
            <Row.Duration.Container>
                <Row.Duration.Value>{flight.duration}</Row.Duration.Value>
                <Row.Duration.Type>direct</Row.Duration.Type>
            </Row.Duration.Container>
        </Row.Col>
        <Row.Col subclasses={['right']}>
            <Row.Selection type="primary" size="small" onClick={selected}>
                $ {flight.price}
            </Row.Selection>
        </Row.Col>
    </Row.Container>
);

export default Component;
