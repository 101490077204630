import { WebPubSub } from 'component/api';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useWebPubSub = ({
    operation,
    hookId,
    actions = {},
    validation = () => undefined,
}) => {
    const args = useRef({});
    const websocket = useRef(null);
    const [triggered, setTriggered] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [listener, setListener] = useState(null);

    const getStorageKey = useCallback(
        () => `hook${hookId}`.toLowerCase(),
        [hookId]
    );

    const stopDiscovery = useCallback(() => {
        if (websocket.current) {
            websocket.current.close();
            websocket.current = null;
        }

        localStorage.removeItem(getStorageKey());
    }, [getStorageKey]);

    const abort = useCallback(() => {
        stopDiscovery();
        setCompleted(false);
        setTriggered(false);
    }, [stopDiscovery]);

    const readWebPubSubMessages = useCallback(async () => {
        const storageKey = getStorageKey();
        const id = localStorage.getItem(storageKey);
        if (id) {
            let pop, queued;
            do {
                pop = await WebPubSub.pop(id);
                const { message } = pop.value;
                queued = pop.queued;

                if (message) {
                    const { origin, type, payload } = message;
                    if (actions[origin] && actions[origin][type]) {
                        const { final, trigger } = actions[origin][type];
                        if (final) stopDiscovery();
                        trigger(payload);
                        if (final) setCompleted(true);
                    } else {
                        console.log('Unsuported message', origin, type);
                    }
                }
            } while (queued > 0);
        }
    }, [getStorageKey, actions, stopDiscovery]);

    useEffect(() => {
        if (!triggered) {
            const storageKey = getStorageKey();
            localStorage.removeItem(storageKey);
        }
    }, [triggered, getStorageKey]);

    useEffect(() => {
        if (completed) stopDiscovery();
    }, [completed, stopDiscovery]);

    useEffect(() => {
        return () => {
            abort();
        };
    }, [abort]);

    useEffect(() => {
        if (!listener) {
            const l = () => {
                if (document.visibilityState === 'visible')
                    readWebPubSubMessages();
            };
            setListener(l);

            document.addEventListener('visibilitychange', l);

            return () => {
                if (listener) {
                    document.removeEventListener('visibilitychange', listener);
                }
            };
        }
    }, [readWebPubSubMessages, listener]);

    const performOperation = useCallback(() => {
        const validationError = validation(args.current);
        if (validationError) {
            return;
        }

        setCompleted(false);
        setTriggered(true);

        const callbacks = {
            200: ({ value: { webpubsub, rid } }) => {
                if (webpubsub) {
                    const storageKey = getStorageKey();
                    localStorage.removeItem(storageKey);
                    localStorage.setItem(storageKey, rid);

                    websocket.current = new WebSocket(webpubsub);
                    websocket.current.onmessage = () => readWebPubSubMessages();
                }
            },
        };

        return operation(args.current)
            .then((result) => {
                if (result?.status) {
                    const c = callbacks[result.status];
                    return c ? c(result) : result;
                }
                return result;
            })
            .catch((err) => {
                console.error(err);
            });
    }, [validation, operation, getStorageKey, readWebPubSubMessages]);

    return {
        abort,
        triggered,
        completed,
        performOperation,
        setArgs: (a) => (args.current = a),
        args: args.current,
    };
};
