import './main.scss';
import './main.css';

const loadThemeDynamic = () => {
    const css = localStorage.getItem('css');
    if (css) {
        const style = document.createElement('style');
        style.innerHTML = css;

        document.head.appendChild(style);
    }

    return css !== null;
};

const loadThemeSite = () => {
    require('./flights.css');
};

const loadTheme = () => {
    if (!loadThemeDynamic()) loadThemeSite();
};

export default loadTheme;
