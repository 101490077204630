import { useNavigate } from 'react-router-dom';

import config from 'utils/config';

import LogoFlights from 'images/logo-flights.png';

const imgProps = {};

switch (config.mode) {
    case 'flights':
        imgProps.src = LogoFlights;
        imgProps.alt = 'Logo flights';
        break;

    default:
        imgProps.src = '';
        imgProps.alt = '';
}

const Component = () => {
    const navigate = useNavigate();

    return (
        <img
            id="go-home-logo"
            onClick={() => navigate('/')}
            src={imgProps.src}
            alt={imgProps.alt}
        />
    );
};

export default Component;
