import { Main as Provider } from 'component/context';

import S from 'theme/structure';

const Component = () => {
    const { user, samlLogin, signOut } = Provider.useContext();

    const buttonConfig = {
        props: {
            variant: user ? 'outline-primary' : 'primary',
            onClick: () => (user ? signOut() : samlLogin()),
        },
        label: user ? 'Logout' : 'Log in or Register',
    };

    return (
        <>
            <S.Login.Button
                subclasses={[user ? 'logout' : 'login']}
                className="button__rounded"
                {...buttonConfig.props}
            >
                {buttonConfig.label}
            </S.Login.Button>
        </>
    );
};

export default Component;
