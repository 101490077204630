export const processResponse = async (res, readPayload = true) => {
    const result = {
        status: res.status,
        value: null,
        error: null,
        payloadError: null,
    };
    if (result.status !== 200) {
        result.error = await res.json();
    } else if (readPayload) {
        try {
            result.value = await res.json();
        } catch (e) {
            result.payloadError = e;
        }
    }
    return result;
};

export const fetcher = async (url, options = {}, readPayload) => {
    options.credentials = 'include';
    if (!options.headers) options.headers = {};

    if (!options.headers['Content-Type'])
        options.headers['Content-Type'] = 'application/json';

    return fetch(url, options)
        .then((res) => processResponse(res, readPayload))
        .then((result) => ({ ...result, debug: { url, options } }));
};
