import React from 'react';

import S from 'theme/structure';

const FormPage = ({
    children,
    pages,
    name,
    selectedPage,
    setSelectedPage,
    labels,
    validateForm,
    isValid,
    handleSubmit,
}) => {
    const page = pages.indexOf(name);

    const handleContinueClick = async () => {
        const errors = await validateForm();
        if (Object.keys(errors).length === 0 && isValid) {
            setSelectedPage(page + 1);
        } else {
            handleSubmit();
        }
    };

    const buttonProperties =
        page === pages.length - 1
            ? {
                  props: { type: 'submit' },
                  label: labels?.end ?? 'Finalise',
              }
            : {
                  props: { onClick: handleContinueClick },
                  label: labels?.continue ?? 'Continue',
              };

    return (
        <S.Form.Page
            subclasses={[name]}
            modifiers={[name === pages[selectedPage] ? 'active' : 'inactive']}
        >
            {children}
            <S.Form.Buttons.Container>
                <S.Form.Buttons.Button
                    variant="primary"
                    size="lg"
                    {...buttonProperties.props}
                >
                    {buttonProperties.label}
                </S.Form.Buttons.Button>
            </S.Form.Buttons.Container>
        </S.Form.Page>
    );
};

export default FormPage;
