import React, { createContext, useContext as useReactContext } from 'react';

import { Flights as Model } from 'demo';

let i = 1;
const flights = Model.Flights.map((flight) => ({ ...flight, id: i++ }));

const lines = {};

let toCities = {};
flights.forEach((flight) => {
    const f = flight.departure.airport;
    const t = flight.arrival.airport;
    const line = f.code + t.code;

    // To Cities are From Airport Name  To Airport Name maps
    if (!toCities[f.name]) toCities[f.name] = [];

    if (!toCities[f.name].includes(t.name)) toCities[f.name].push(t.name);

    // Lines are From Airport Code + To Airport Code arrays
    if (!lines[line]) {
        lines[line] = [];
    }
    lines[line].push(flight);
});

const cities = {
    from: Object.keys(toCities),
    to: toCities,
};

// Sort lines by departure time
Object.keys(lines).forEach((line) => {
    lines[line].sort((a, b) => {
        const timeA = new Date('1970/01/01 ' + a.departure.time);
        const timeB = new Date('1970/01/01 ' + b.departure.time);
        return timeA - timeB;
    });
});

export const Context = createContext();

export const Provider = ({ children }) => {
    return (
        <Context.Provider
            value={{
                flights,
                airports: Model.Airports,
                cities,
                lines,
                companies: Model.Companies,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export const useContext = () => {
    const context = useReactContext(Context);
    if (!context) {
        throw new Error('useContext should be used inside Provider');
    }
    return context;
};
