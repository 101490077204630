import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { User, SAML, Flights } from 'component/model';

const ProviderWrapper = ({ children }) => (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <User.Provider>
            <SAML.Provider>{children}</SAML.Provider>
        </User.Provider>
    </GoogleOAuthProvider>
);

export const Provider = ({ children }) => (
    <ProviderWrapper>
        <Flights.Provider>{children}</Flights.Provider>
    </ProviderWrapper>
);

export const useContext = () => ({
    ...Flights.useContext(),
    ...User.useContext(),
    ...SAML.useContext(),
});
