import * as Utils from './Utils';

export const obtainBoardingPass = (claims) =>
    Utils.fetcher(
        `${process.env.REACT_APP_API}/public/${process.env.REACT_APP_API_VERSION}/flight/boardingpass/vc`,
        {
            method: 'POST',
            body: JSON.stringify(claims),
        }
    );
