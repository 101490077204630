import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Panel, FormWrapper } from 'component/common';
import { Main as Provider } from 'component/context';
import { User } from 'component/api';
import { Form } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { serialize } from 'utils/serialization';
import Select from 'react-select';
import { Formik } from 'formik';
import * as yup from 'yup';

import S from 'theme/structure';

const preferences = {
    'Seat preferences': ['Aisle', 'Extra leg room', 'Front seats', 'Window'],
    'Accessibility needs': [
        'Cognitive disability',
        'Oxygen or other assistive devices',
        'Service animal',
        'Travelling with a carer',
        'Visually impaired',
        'Wheelchair dependent or other mobility aids',
    ],
    Allergies: ['Egg', 'Peanut', 'SeaFood', 'SoyBean', 'TreeNuts'],
    'Dietary preferences': [
        'Dairy free',
        'Gluten free',
        'Halal',
        'Kosher',
        'Pescatarian',
        'Vegan',
        'Vegetarian',
    ],
    'Preferred cuisines': [
        'Brazilian',
        'Chinese',
        'French',
        'Greek',
        'Indian',
        'Italian',
        'Japanese',
        'Korean',
        'Lebanese',
        'Mexican',
        'Moroccan',
        'Spanish',
        'Thai',
        'Turkish',
        'Vietnamese',
    ],
};

const selectValues = Object.keys(preferences).reduce((acc, key) => {
    acc[key] = preferences[key].map((v) => ({ value: v, label: v }));
    return acc;
}, {});

const preparePreferences = (preferences = {}) =>
    Object.keys(preferences).reduce((acc, key) => {
        let k = key.replaceAll('_', ' ');
        k = k.charAt(0).toUpperCase() + k.slice(1);
        acc[k] = Object.keys(preferences[key]).reduce((acc, subKey) => {
            let sk = subKey.replaceAll('_', ' ');
            sk = sk.charAt(0).toUpperCase() + sk.slice(1);
            acc[sk] = preferences[key][subKey];
            return acc;
        }, {});
        return acc;
    }, {});

const computeSelectedValues = (preferences) => {
    const result = {};
    Object.keys(preferences).forEach((key) => {
        result[key] = [];
        const p = preferences[key];
        Object.keys(p).forEach((value) => {
            const state = p[value];
            if (state === true || state === 'true')
                result[key].push({ value, label: value });
        });
    });

    return result;
};

const computeValuePreferences = (key, form) => {
    const result = {};
    for (const value of selectValues[key]) {
        result[value.label] = false;
    }
    if (form[key]) {
        for (const value of form[key]) {
            result[value.value] = true;
        }
    }
    return result;
};

const buttonsLabels = { continue: 'Continue', end: 'Finalise your booking' };

const assignSeat = (seatPreferences = []) => {
    let row, column;
    const aisle =
        seatPreferences.find((v) => v.value === 'Aisle') !== undefined;
    const extra_leg_room =
        seatPreferences.find((v) => v.value === 'Extra leg room') !== undefined;
    const front_seats =
        seatPreferences.find((v) => v.value === 'Front seats') !== undefined;
    const window =
        seatPreferences.find((v) => v.value === 'Window') !== undefined;

    if (front_seats && extra_leg_room) {
        row = 1;
    } else if (front_seats) {
        row = Math.floor(Math.random() * 10) + 1;
    } else if (extra_leg_room) {
        row = Math.random() < 0.5 ? 15 : 18;
    } else {
        row = Math.floor(Math.random() * 70) + 1;
    }

    const columns = ['A', 'B', 'C', 'D', 'E', 'F'];
    if (aisle) {
        column = Math.random() < 0.5 ? 'C' : 'D';
    } else if (window) {
        column = Math.random() < 0.5 ? 'A' : 'F';
    } else {
        column = columns[Math.floor(Math.random() * columns.length)];
    }

    return `${row}${column}`;
};

const hashString = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0;
    }
    return hash;
};

const adjustTime = (timeStr, minutesToSubtract) => {
    const timeParts = timeStr.split(':');
    const date = new Date();
    date.setHours(
        parseInt(timeParts[0]),
        parseInt(timeParts[1]) - minutesToSubtract,
        0,
        0
    );

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
};

const calculateBoardingAndGateClosed = (flightTime) => {
    const boardingTime = adjustTime(flightTime, 60);
    const gateClosedTime = adjustTime(flightTime, 25);
    return { boardingTime, gateClosedTime };
};

const Component = () => {
    const { flights, user, setUser } = Provider.useContext();
    const navigate = useNavigate();
    const [selectedPage, setSelectedPage] = useState(0);
    const [writingData, setWritingData] = useState(false);
    const [flightData, setFlightData] = useState(undefined);

    const pages = ['passenger', 'preferences'];
    const pagesTitles = {
        passenger: 'Passenger info',
        preferences: 'Preferences',
    };

    useEffect(() => {
        if (flightData && !writingData) {
            if (user) {
                setUser({
                    ...user,
                    profile: {
                        firstName: flightData.passenger.firstName,
                        lastName: flightData.passenger.lastName,
                        emailAddress: flightData.passenger.emailAddress,
                        phoneNumber: flightData.passenger.phoneNumber,
                    },
                    preferences: flightData.preferences,
                });
            }
            navigate(`/confirmation?flight=${serialize(flightData)}`);
        }
    }, [writingData, flightData, navigate, user, setUser]);

    const { flightId, date } = useParams();

    const p = flightId - 1;
    const flight = p >= 0 && p < flights.length ? flights[p] : undefined;

    const initialValues = {
        firstName: user?.profile?.firstName ?? '',
        lastName: user?.profile?.lastName ?? '',
        emailAddress: user?.profile?.emailAddress ?? '',
        phoneNumber: user?.profile?.phoneNumber ?? '',
        ...computeSelectedValues(preparePreferences(user?.preferences ?? {})),
    };

    const PassengerPage = ({
        handleChange,
        values,
        touched,
        errors,
        validateForm,
        isValid,
        handleSubmit,
    }) => (
        <FormWrapper.Page
            name="passenger"
            pages={pages}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            validateForm={validateForm}
            isValid={isValid}
            handleSubmit={handleSubmit}
            labels={buttonsLabels}
        >
            <FormWrapper.Row label="First name" error={errors.firstName}>
                <Form.Control
                    id="firstName"
                    type="text"
                    placeholder="Enter your first name"
                    value={values.firstName}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                />
            </FormWrapper.Row>
            <FormWrapper.Row label="Last name" error={errors.lastName}>
                <Form.Control
                    id="lastName"
                    type="text"
                    placeholder="Enter your last name"
                    value={values.lastName}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                />
            </FormWrapper.Row>

            <S.Form.Section.Title>Contact information</S.Form.Section.Title>

            <FormWrapper.Row label="E-mail address" error={errors.emailAddress}>
                <Form.Control
                    id="emailAddress"
                    type="email"
                    placeholder="Enter your e-mail address"
                    value={values.emailAddress}
                    onChange={handleChange}
                    isInvalid={touched.emailAddress && !!errors.emailAddress}
                />
            </FormWrapper.Row>
            <FormWrapper.Row label="Phone number">
                <Form.Control
                    id="phoneNumber"
                    type="tel"
                    placeholder="Enter your phone number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    isInvalid={touched.phoneNumber && !!errors.phoneNumber}
                />
            </FormWrapper.Row>
        </FormWrapper.Page>
    );

    const PreferencesPage = ({
        values,
        setFieldValue,
        validateForm,
        isValid,
        handleSubmit,
    }) => (
        <FormWrapper.Page
            name="preferences"
            pages={pages}
            selectedPage={selectedPage}
            setSelectedPage={setSelectedPage}
            validateForm={validateForm}
            isValid={isValid}
            handleSubmit={handleSubmit}
            labels={buttonsLabels}
        >
            <FormWrapper.Row label="In-flight seat preferences">
                <Select
                    id="Seat preferences"
                    placeholder="Select your preferences"
                    isSearchable={false}
                    isMulti
                    options={selectValues['Seat preferences']}
                    value={values['Seat preferences']}
                    onChange={(v) => setFieldValue('Seat preferences', v)}
                />
            </FormWrapper.Row>

            <FormWrapper.Row label="Accessibility needs">
                <Select
                    id="Accessibility needs"
                    placeholder="Select your needs"
                    isSearchable={false}
                    isMulti
                    options={selectValues['Accessibility needs']}
                    value={values['Accessibility needs']}
                    onChange={(v) => setFieldValue('Accessibility needs', v)}
                />
            </FormWrapper.Row>

            <FormWrapper.Row label="Allergies">
                <Select
                    id="Allergies"
                    placeholder="Select your allergies"
                    isSearchable={false}
                    isMulti
                    options={selectValues['Allergies']}
                    value={values['Allergies']}
                    onChange={(v) => setFieldValue('Allergies', v)}
                />
            </FormWrapper.Row>

            <FormWrapper.Row label="Dietary preferences">
                <Select
                    id="Dietary preferences"
                    placeholder="Select your preferences"
                    isSearchable={false}
                    isMulti
                    options={selectValues['Dietary preferences']}
                    value={values['Dietary preferences']}
                    onChange={(v) => setFieldValue('Dietary preferences', v)}
                />
            </FormWrapper.Row>

            <FormWrapper.Row label="Preferred cuisines">
                <Select
                    id="Preferred cuisines"
                    placeholder="Select your preferences"
                    isSearchable={false}
                    isMulti
                    options={selectValues['Preferred cuisines']}
                    value={values['Preferred cuisines']}
                    onChange={(v) => setFieldValue('Preferred cuisines', v)}
                />
            </FormWrapper.Row>
        </FormWrapper.Page>
    );

    return (
        <S.Container.Page>
            <Panel
                header={pagesTitles[pages[selectedPage]]}
                handleBack={() =>
                    !selectedPage
                        ? window.history.back()
                        : setSelectedPage(selectedPage - 1)
                }
            >
                <Formik
                    validationSchema={yup.object({
                        firstName: yup
                            .string()
                            .required('Please enter your first name'),
                        lastName: yup
                            .string()
                            .required('Please enter your last name'),
                        emailAddress: yup
                            .string()
                            .email('Please enter a valid e-mail address')
                            .required('Please enter your e-mail address'),
                        phoneNumber: yup.number().integer(),
                        'Seat preferences': yup.array().of(
                            yup.object({
                                value: yup.string(),
                                label: yup.string(),
                            })
                        ),
                        'Accessibility needs': yup.array().of(
                            yup.object({
                                value: yup.string(),
                                label: yup.string(),
                            })
                        ),
                        Allergies: yup.array().of(
                            yup.object({
                                value: yup.string(),
                                label: yup.string(),
                            })
                        ),
                        'Dietary preferences': yup.array().of(
                            yup.object({
                                value: yup.string(),
                                label: yup.string(),
                            })
                        ),
                        'Preferred cuisines': yup.array().of(
                            yup.object({
                                value: yup.string(),
                                label: yup.string(),
                            })
                        ),
                    })}
                    initialValues={initialValues}
                    onSubmit={(form) => {
                        const id = uuid();
                        const line = Math.abs(
                            hashString(
                                flight.departure.airport.code +
                                    flight.arrival.airport.code
                            )
                        );
                        const flightData = {
                            flight: {
                                company: flight.company.name,
                                departure: flight.departure,
                                arrival: flight.arrival,
                                price: flight.price,
                                duration: flight.duration,
                                number: `FLT${flightId.padStart(3, '0')}`,
                                terminal: line % 2 ? 'A' : 'B',
                                gate: (line % 45) + 1,
                                additionalTimes: calculateBoardingAndGateClosed(
                                    flight.departure.time
                                ),
                            },
                            ticket: {
                                number: id.replaceAll('-', '').substring(0, 16),
                                seat: assignSeat(form['Seat preferences']),
                                boardingPassCode: id,
                            },
                            date,
                            passenger: {
                                firstName: form.firstName,
                                lastName: form.lastName,
                                emailAddress: form.emailAddress,
                                phoneNumber: form.phoneNumber,
                                loyaltyCardStatus: 'Silver',
                            },
                            preferences: {
                                'Seat preferences': computeValuePreferences(
                                    'Seat preferences',
                                    form
                                ),
                                'Accessibility needs': computeValuePreferences(
                                    'Accessibility needs',
                                    form
                                ),
                                Allergies: computeValuePreferences(
                                    'Allergies',
                                    form
                                ),
                                'Dietary preferences': computeValuePreferences(
                                    'Dietary preferences',
                                    form
                                ),
                                'Preferred cuisines': computeValuePreferences(
                                    'Preferred cuisines',
                                    form
                                ),
                            },
                        };

                        setWritingData(true);
                        setFlightData(flightData);
                        if (user) {
                            const preferences = Object.keys(
                                flightData.preferences
                            ).reduce((acc, key) => {
                                acc[key.replaceAll(' ', '_').toLowerCase()] =
                                    Object.keys(
                                        flightData.preferences[key]
                                    ).reduce((acc, subKey) => {
                                        acc[
                                            subKey
                                                .replaceAll(' ', '_')
                                                .toLowerCase()
                                        ] = flightData.preferences[key][subKey];
                                        return acc;
                                    }, {});
                                return acc;
                            }, {});
                            const cwData = {
                                profile: {
                                    firstName: flightData.passenger.firstName,
                                    lastName: flightData.passenger.lastName,
                                    emailAddress:
                                        flightData.passenger.emailAddress,
                                    phoneNumber:
                                        flightData.passenger.phoneNumber,
                                },
                                preferences,
                            };
                            User.writeData(cwData)
                                .then(() => setWritingData(false))
                                .catch((err) => {
                                    window.alert(err.message);
                                    setWritingData(false);
                                });
                        } else {
                            setWritingData(false);
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        setFieldValue,
                        values,
                        touched,
                        errors,
                        validateForm,
                        isValid,
                    }) => (
                        <S.Form.Container
                            id="form-flight-book"
                            noValidate
                            onSubmit={handleSubmit}
                        >
                            <PassengerPage
                                values={values}
                                touched={touched}
                                errors={errors}
                                handleChange={handleChange}
                                validateForm={validateForm}
                                isValid={isValid}
                                handleSubmit={handleSubmit}
                            />
                            <PreferencesPage
                                values={values}
                                touched={touched}
                                errors={errors}
                                setFieldValue={setFieldValue}
                                validateForm={validateForm}
                                isValid={isValid}
                                handleSubmit={handleSubmit}
                            />
                        </S.Form.Container>
                    )}
                </Formik>
            </Panel>
        </S.Container.Page>
    );
};

export default Component;
